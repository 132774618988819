// h-d.com global variables
$env: 'aem' !default;
$dam: '/content/dam/h-d' !default;
$root: '/etc/designs/h-d' !default;

@if ($env =='hybris') {
  $root: '..';
}

$font-path: $dam + '/fonts/';
// updated fonts path
//$lang-font-path: $root + '/../fonts/';
// fonts are now in the aem dam so adjusting the relative path for the languages is no longer needed
$lang-font-path: $font-path;
$image-path: $root + '/images/';

// font family names
$font-primary: 'Primary-Font';
$font-primary-italic: 'Primary-Font-Italic';
//$font-primary-medium: 'Primary-Font-Medium';
$font-primary-bold: 'Primary-Font-Bold';
$font-primary-heavy: 'Primary-Font-Heavy';
$font-secondary: 'Secondary-Font';
$font-secondary-bold: 'Secondary-Font-Bold';
$font-tertiary: 'Tertiary-Font';
$font-tertiary-italic: 'Tertiary-Font-Italic';
$font-tertiary-bold: 'Tertiary-Font-Bold';

// colors by color
$color-hd-orange: #fa6600; // updated to color ada oj;
$color-ada-orange: #fa6600;
$color-punch-orange: #ff9b3f;
$color-orange: #ff6600;
$color-ada-blue: #0067f4;
$color-burnt-orange: #ad4c0b;
$color-leathered-orange: #ad4c0b; // updated to color burnt oj;
$color-secondary-orange: #C2550C; // AA Web-Safe Burnt Orange
$color-worn-tshirt: #efede6; // replaced with t-shirt grey;
$color-red: #b22705;
$color-hex-red: #ce4635;
$color-gigya-error: #dd4b39;
$color-status-red: #C30000;
$color-status-green: #014931;
// monochrome colors
$color-black: #000000;
$color-dark-grey: #757575; // replaced with middle grey;
$color-middle-grey: #757575;
$color-green: #468600;
$color-dark-green: #43870b;
$color-input-green: #4a8d00;
$color-very-light-grey: #e1e1e1;
$color-light-grey: #eeeceb;
$color-grey: #7c7c7c;
$color-grey-light: #CCCCCC;
$color-grey-light-1: #D8D8D8;
$color-grey-light-2: #949494;
$color-grey-light-3: #666666;
$color-whitish-grey: #F6F6F6;
$color-light-shade-blue: #ced4da;
$color-grayish-blue: #212529;
$color-lite-grayish-blue: #6c757d;
$color-unavail-grey: $color-light-grey; // replaced with light grey
$color-off-white: #f8f6f5; // used only for hover states on accordions, quick links, disclaimers
$color-off-light-grey: darken($color-light-grey, 3%);
$color-off-worn-tshirt: darken($color-worn-tshirt, 3%);
$color-off-black: rgba(255, 255, 255, 0.1);
$color-tshirt-grey: #efede6;
$color-white: #ffffff;
$color-matterhorn: #505050;

// colors by specific use
$color-selection: $color-light-grey;
$color-link: #ffffff;
$color-link-hover: #ffffff;
$color-form-grey: $color-middle-grey;
$color-form-red: #d60000;
$color-form-black: $color-black;
$color-border-dark: $color-middle-grey;
$color-border-light: $color-light-grey;
$color-disclaimer: $color-middle-grey;
$color-disclaimer-black: $color-black;
$color-column-divider: $color-middle-grey;

// margins
$cmp-margin-none: 0;
$cmp-margin-standard: rem(32);
$cmp-margin-extra: rem(64);

// ada
$ada-outline: 5px auto $color-ada-blue;

// vars for datepicker lib
//$color-orange-1: #de571e !default;
//$gray-base: #000 !default;
//$gray-darker: lighten($gray-base, 13.5%) !default; // #222
//$gray-dark: lighten($gray-base, 20%) !default;   // #333
//$gray: lighten($gray-base, 33.5%) !default; // #555
//$gray-light: lighten($gray-base, 46.7%) !default; // #777
//$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
//$brand-info: #5bc0de !default;
//$brand-primary: darken(#428bca, 6.5%) !default; // #337ab7
//$btn-primary-color: #fff !default;
//$btn-primary-bg: $brand-primary !default;
//$btn-primary-border: darken($btn-primary-bg, 5%) !default;