@import '../../sass/global/variables';
@import '../../sass/global/bootstrap-variables';
@import '../../sass/global/mixins';
@import 'bootstrap/scss/mixins';

// previous font families
//@include font-face($font-primary, $lang-font-path + "amerigo/roman");
//@include font-face($font-primary-italic, $lang-font-path + "amerigo/italic");
//@include font-face($font-primary-bold, $lang-font-path + "amerigo/roman");

//@include font-face($font-secondary, $lang-font-path + "ff-din/condensed");
//@include font-face($font-secondary-bold, $lang-font-path + "ff-din/condensed-bold");

//@include font-face($font-tertiary, $lang-font-path + "ff-din/regular");
//@include font-face($font-tertiary-italic, $lang-font-path + "ff-din/regular-italic");
//@include font-face($font-tertiary-bold, $lang-font-path + "ff-din/bold");

// new typekit font families
// https://use.typekit.net/lef8bpu.css

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * franklin-gothic-atf:
 *   - http://typekit.com/eulas/00000000000000003b9b4448
 *   - http://typekit.com/eulas/00000000000000003b9b4449
 *   - http://typekit.com/eulas/00000000000000003b9b444a
 *   - http://typekit.com/eulas/00000000000000003b9b444c
 *   - http://typekit.com/eulas/00000000000000003b9b444e
 * franklin-gothic-compressed:
 *   - http://typekit.com/eulas/00000000000000003b9b1f71
 *   - http://typekit.com/eulas/00000000000000003b9b1f73
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-12-18 02:01:35 UTC"}*/

@font-face {
  //font-family: "franklin-gothic-atf";
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
  src: local('Primary-Font'),
    url('https://use.typekit.net/af/2727fa/00000000000000003b9b4448/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),
    url('https://use.typekit.net/af/2727fa/00000000000000003b9b4448/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'),
    url('https://use.typekit.net/af/2727fa/00000000000000003b9b4448/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
  font-display: swap;
}

@font-face {
  //font-family: "franklin-gothic-atf";
  font-family: $font-primary-italic;
  font-style: italic;
  font-weight: 400;
  src: local('Primary-Font-Italic'),
    url('https://use.typekit.net/af/45aa8b/00000000000000003b9b4449/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3') format('woff2'),
    url('https://use.typekit.net/af/45aa8b/00000000000000003b9b4449/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3') format('woff'),
    url('https://use.typekit.net/af/45aa8b/00000000000000003b9b4449/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3') format('opentype');
  font-display: swap;
}

/*@font-face {
  //font-family: "franklin-gothic-atf";
  font-family: $font-primary-medium;
  font-style: normal;
  font-weight: 500;
  src: local('Primary-Font-Medium'),
       url("https://use.typekit.net/af/317935/00000000000000003b9b444a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),
       url("https://use.typekit.net/af/317935/00000000000000003b9b444a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),
       url("https://use.typekit.net/af/317935/00000000000000003b9b444a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: swap;
}*/

@font-face {
  //font-family: "franklin-gothic-atf";
  font-family: $font-primary-bold;
  font-style: normal;
  font-weight: 600;
  src: local('Primary-Font-Bold'),
    url('https://use.typekit.net/af/a9cf1b/00000000000000003b9b444c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('woff2'),
    url('https://use.typekit.net/af/a9cf1b/00000000000000003b9b444c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('woff'),
    url('https://use.typekit.net/af/a9cf1b/00000000000000003b9b444c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('opentype');
  font-display: swap;
}

@font-face {
  //font-family: "franklin-gothic-atf";
  font-family: $font-primary-heavy;
  font-style: normal;
  font-weight: 800;
  src: local('Primary-Font-Heavy'),
    url('https://use.typekit.net/af/e6e225/00000000000000003b9b444e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3') format('woff2'),
    url('https://use.typekit.net/af/e6e225/00000000000000003b9b444e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3') format('woff'),
    url('https://use.typekit.net/af/e6e225/00000000000000003b9b444e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3') format('opentype');
  font-display: swap;
}

@font-face {
  //font-family: "franklin-gothic-compressed";
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 400;
  src: local('Secondary-Font'),
    url('https://use.typekit.net/af/a45f7b/00000000000000003b9b1f71/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),
    url('https://use.typekit.net/af/a45f7b/00000000000000003b9b1f71/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'),
    url('https://use.typekit.net/af/a45f7b/00000000000000003b9b1f71/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
  font-display: swap;
}

@font-face {
  //font-family: "franklin-gothic-compressed";
  font-family: $font-secondary-bold;
  font-style: normal;
  font-weight: 600;
  src: local('Secondary-Font-Bold'),
    url('https://use.typekit.net/af/40578a/00000000000000003b9b1f73/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('woff2'),
    url('https://use.typekit.net/af/40578a/00000000000000003b9b1f73/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('woff'),
    url('https://use.typekit.net/af/40578a/00000000000000003b9b1f73/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('opentype');
  font-display: swap;
}

@font-face {
  //font-family: "franklin-gothic-atf";
  font-family: $font-tertiary;
  font-style: normal;
  font-weight: 400;
  src: local('Tertiary-Font'),
    url('https://use.typekit.net/af/2727fa/00000000000000003b9b4448/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),
    url('https://use.typekit.net/af/2727fa/00000000000000003b9b4448/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'),
    url('https://use.typekit.net/af/2727fa/00000000000000003b9b4448/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');
  font-display: swap;
}

@font-face {
  //font-family: "franklin-gothic-atf";
  font-family: $font-tertiary-italic;
  font-style: italic;
  font-weight: 400;
  src: local('Tertiary-Font-Italic'),
    url('https://use.typekit.net/af/45aa8b/00000000000000003b9b4449/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3') format('woff2'),
    url('https://use.typekit.net/af/45aa8b/00000000000000003b9b4449/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3') format('woff'),
    url('https://use.typekit.net/af/45aa8b/00000000000000003b9b4449/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3') format('opentype');
  font-display: swap;
}

@font-face {
  //font-family: "franklin-gothic-atf";
  font-family: $font-tertiary-bold;
  font-style: normal;
  font-weight: 600;
  src: local('Tertiary-Font-Bold'),
    url('https://use.typekit.net/af/a9cf1b/00000000000000003b9b444c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('woff2'),
    url('https://use.typekit.net/af/a9cf1b/00000000000000003b9b444c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('woff'),
    url('https://use.typekit.net/af/a9cf1b/00000000000000003b9b444c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3') format('opentype');
  font-display: swap;
}

.tk-franklin-gothic-compressed {
  font-family: 'franklin-gothic-compressed', sans-serif;
}
.tk-franklin-gothic-atf {
  font-family: 'franklin-gothic-atf', sans-serif;
}

// font weights
@import '_weights-franklin-gothic';
